import { CommonModule, NgIf, NgFor } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OptionItemModel } from '../../modules/isolated.components/dropdown/model';
import { GqlError } from 'src/app/services/graphql.module/model';

@Component({
    selector: 'pb-dropdown',
    templateUrl: './pb-dropdown.component.html',
    styleUrls: ['./pb-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, NgIf, FormsModule, NgFor]
})
export class PbDropdownComponent implements OnInit {

  constructor() { }

  private _options:Array<OptionItemModel>;
  private _selectedValue?:any;
  private _gqlErrors: Array<GqlError>;
  errorStrings: Array<string> = new Array<string>();
  isPlaceholderDisabled:boolean = false;
  hasErrors:boolean = false;
  showCaption:boolean = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() name: string;
  @Input() classes: string;
  @Input() caption:string;
  @Input() isDisabled: boolean = false;
  @Input() placeholder:string = "";
  @Input() firstNullValue:string = "";
  @Input() borderless:boolean = false;

  @Input() returnObjectsForSelectedValue:boolean = false;
  
  @Input() set options (value:Array<OptionItemModel>){
    this._options = value;
  }
  
  get options():Array<OptionItemModel>{
    return this._options;
  }

  @Input() set selectedValue(value:any){

    if (value == this.firstNullValue){
      this._selectedValue = null;
    }
    else{
      this._selectedValue = value;
    }
    
    if (!value && value!=0){
      this.showCaption = false;
    }
    else{
      this.showCaption = true;
      //this.isPlaceholderDisabled = true;
      this.hasErrors = false;
    }
  
  }

  @Input() set gqlErrors (value: Array<GqlError>){
      this._gqlErrors = value;
      this.errorStrings = [];
      if (this._gqlErrors != undefined) {
        this._gqlErrors.filter(e => e.source == this.name).forEach(e => this.errorStrings.push(e.message));
    
        if (this.errorStrings.length>0){
          this.hasErrors = true;
        }
        else{
          this.hasErrors = false;
        }
      }
      else{
        this.hasErrors = false;
      }
  };

  get gqlErrors():Array<GqlError>{
    return this._gqlErrors;
  }

  get errorText():string{
    return this.errorStrings.join(', ');
  }

  ngOnInit(): void {}

  onChange(newValue){
    if (this.placeholder && this.placeholder==newValue || this.firstNullValue && this.firstNullValue==newValue){
      this.selectedValueChange.emit(null);
      this.showCaption = false;
    }
    else{
      if (this.returnObjectsForSelectedValue){
        var selectedVal = this.options.find(o => o.key == newValue);
        this.selectedValueChange.emit(selectedVal);
      }
      else if (this.isNumber(newValue)){
        this.selectedValueChange.emit(+newValue);  
      }
      else if (newValue == "true" || newValue == "false"){
        this.selectedValueChange.emit(newValue === "true" ? true : false);
      }
      else{
        this.selectedValueChange.emit(newValue);
      }
      
      this.showCaption = true;
      //this.isPlaceholderDisabled = true;
    }
  }

  isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

  get selectedValue():any{
    return this._selectedValue;
  };

  isSelected(option:OptionItemModel){
    if (this.returnObjectsForSelectedValue){
      if (this.selectedValue && typeof(this.selectedValue) == "object"){
        if (this.selectedValue.key == option.key){
          return true;
        }
        return false;
      }
    }
    else{
      if (String(this.selectedValue) == String(option.key)){
        return true;
      }
    }
    
    return false;
  }
}


