<div class="pb-dropdown {{classes}}">
    <div class="select-container {{hasErrors?'error':''}} {{borderless?'borderless':''}}">
        <span class="small-caption {{showCaption && !hasErrors?'show':''}}">{{caption}}</span>
        <span class="small-error {{hasErrors?'show':''}}">{{errorText}}</span>
        <select [attr.disabled]="isDisabled?'':null" (change)="onChange($event.target.value)" class="{{showCaption===true || hasErrors===true?'ph-visible':''}}">
          <option [attr.disabled]="isPlaceholderDisabled?'':null" [ngValue]="null" *ngIf="this.placeholder">{{this.placeholder}}</option>
          <option [ngValue]="null" *ngIf="this.firstNullValue">{{this.firstNullValue}}</option>
          <option [selected]="isSelected(option)" *ngFor="let option of options" [value]=option.key [attr.disabled]="option.isDisabled?'':null" >{{option.value}}</option>
        </select>
    </div>
</div>

